@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
@font-face {
  font-family: 'Roboto';
  font-display: swap;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


#root {
    background-color: black;
    font-family: 'Roboto', sans-serif;
}


@keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .cool-animation {
    display: inline-block;
    animation: pulse 1.5s infinite;
  }

  
  .trustpilot-rating {
    background-color: black;
    color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: center;
}

.review-text {
    margin-bottom: 0.5rem;
}

.excellent {
    color: #FFBF00; /* Change this color as needed */
    font-weight: bold;
}

.stars svg {
    display: inline-block;
    margin-left: 0.25rem;
}

.rating-text {
    margin-bottom: 0.5rem;
}

.rating-score {
    font-weight: bold;
    font-size: 1.25rem;
}

.reviews {
    font-weight: normal;
    font-size: 1rem;
}

.trustpilot {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.trustpilot-logo {
    width: 100px;
    height: auto;
}

.trustpilot-text {
    margin-left: 0.5rem;
}

.text_container {
    display: flex;
    gap: 100px;
    padding: 99px;
}

.heading{
    color: #ff5454;
    margin-bottom: 22px;
    font-weight: bold;
}

.subhead{
    margin-bottom: 50px;
}

@media (max-width: 1024px) {
    .text_container {
        display: grid;
        gap: 100px;
        padding: 99px 20px;
    }
    .text_section {
        text-align: center;
    }

    .icon_nav {
      margin-right: 35px;
      font-size: 22px;
  }


  .mobile_faq{
    text-align: -webkit-center;
    padding: 0;
  }

  .alert_mobile{
    margin: 0;
  }
}

.experience_div{
    background-color: rgb(24, 19, 19);
    width: 149px;
    height: 120px;
    border-radius: 16px;
    position: absolute;
    top: 310px;
    right: -49px;
}

/* Custom styles for the image section and experience div */
.image_section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .image_section img {
    width: 100%;
    border-radius: 1rem;
    object-fit: cover;
  }
  
  .experience_div {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .experience_div p {
    margin: 0;
    font-size: 2rem;
  }

  /* Add custom styles for the carousel images */
.carousel-image {
    width: 192px; /* Increase the size as needed */
    border: 2px solid white; /* Add white border */
    margin-right: 10px; /* Remove the gap between images */
    display: block; /* Ensure images are block level */
  }
  
  .carousel-item {
    padding: 0; /* Remove padding from items */
  }
  
  .video-container {
    position: relative;
    background: black;
    padding-top: 150px;
    overflow: hidden;
    width: 100%;
    height: 80vh; /* Adjust as needed */
  }
  
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the container */
  }

  .overlay2 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 1));
}

.gradient-hover {
  transition: all 300ms;
}
.gradient-hover:hover {
  background: linear-gradient(to right, rgb(84, 84, 211) 0%, rgb(207, 55, 55) 100%);
}

/* Add these styles to your index.css or relevant CSS file */
.cart-sidebar {
  transition: transform 0.5s ease;
}

.cart-sidebar.closed {
  transform: translateX(100%);
}

.cart-sidebar.open {
  transform: translateX(0);
}

/* In your index.css or a separate CSS file */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

.alert {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.alert.show {
  opacity: 1;
}


.alert.fade-out {
  animation: fadeOut 0.5s forwards;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's above other elements */
  visibility: hidden; /* Initially hidden */
}

.overlay.active {
  visibility: visible; /* Show when active */
}

.content {
  background: white;
  padding: 20px;
  border-radius: 5px;
}

/* If using plain CSS, ensure you have these styles */

.card {
  backdrop-filter: blur(10px); /* Example value for large blur */
  transition: backdrop-filter 0.3s ease;
}

.card:hover {
  backdrop-filter: none;
}

.fade-alert {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-alert.show {
  opacity: 1;
}

.alert_mobile{
  margin: 25px 250px;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #25b09b;
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15{ 
  100%{transform: rotate(1turn)}
}

.carousel-image {
  height: 250px; 
  width: 250px; 
  object-fit: cover; 
}

.product-details-container {
  background-image: url('https://removal.ai/wp-content/uploads/2021/09/black-background-04-coolbackgrounds.png'); /* Replace with the path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	left:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}

.realshit{
  padding: 120px;
}

@media (max-width: 1024px) {
  img.rounded-lg.mb-4 {
    place-self: center;
    width: 201px;
    padding-top: 73px;
  }

  .elite_container {
      padding: 0 20px;
  }

  .realshit{
    padding: 0;
    display: grid;
  }
}